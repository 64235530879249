import styled from 'styled-components';

export const InfoBar = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #FFF;
  color: #1A1D1F;
  line-height: 25px;
  text-align: left;
  padding: 10px 20px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #00CCAE;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 1200px) {
    left: 0;
    transform: none;
    width: 100%;
  }

  .close-btn {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
  }
`;