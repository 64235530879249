import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";
import "../../i18n";

import { routes } from "../../routes";
import { AuthContext } from "../../contexts";
import Loader from "../../components/Loader";
import { LANGUAGE } from "../../options/preference";
import logo from "../../assets/logo_preto_sem_digital_v2.png";
import { setUserInfo, getPreviousPath, removePreviousPath, saveToken } from "../../utils/loginUtils";
import { authenticate, resetPassword, validateMfaCode, editMyUserSettings } from "../../services/odinService";

import { Container, Login, SelectLanguage } from "./styles";

const RESEND_MFA_CODE_TIMEOUT = 60;

function SignIn() {
  const { 
    authenticated, 
    handleSignInSuccess, 
    changeLanguage,
    lang 
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [authTemp, setAuthTemp] = useState(null);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [forgetPasswordUsername, setForgetPasswordUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMfaCodeResend, setIsLoadingMfaCodeResend] = useState(false)
  const [mfaStep, setMfaStep] = useState(false);
  const [mfaCodeResendDisabled, setMfaCodeResendDisabled] = useState(true);
  const [mfaCodeResendTimer, setMfaCodeResendTimer] = useState(RESEND_MFA_CODE_TIMEOUT);
  const [pushToken, setPushToken] = useState("");

  const getPushTokenFromQueryStringURL = useCallback(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    let pushToken = "";

    searchParams.forEach(function (value, key) {
      if (key && !value) {
        pushToken = key;
      }
    });

    return pushToken;
  }, []);

  const getAndSetLanguageFromQueryStringURL = useCallback(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);

    let lang = "";

    searchParams.forEach(function (value, key) {
      if (key === "lang" && value) {
        lang = value;
      }
    });

    if (Object.values(LANGUAGE).includes(lang)) {
      changeLanguage(lang);
    }
  }, [changeLanguage]);

  const handleChangeSignInLanguage = (lang) => {
    window.location.search = `?lang=${lang}`;
  };

  const data = { username, password };

  const handleMfaInputChange = (value) => {
    if (/^\d*$/.test(value)) {
      setMfaCode(value);
    }
  };
  
  const handleReturnToInitialSignIn = () => {
    setMfaStep(false);
    setForgetPassword(false);
    setForgetPasswordUsername("");
    setMfaCodeResendDisabled(true);
    setMfaCodeResendTimer(RESEND_MFA_CODE_TIMEOUT);
    setMfaCode("");
  };

  const handleForgetPassword = async (e) => {
    e.preventDefault();

    if (!forgetPasswordUsername) {
      toast.error(t("toast.username_required"));
    } else {
      try {
        setIsLoading(true);

        await resetPassword(forgetPasswordUsername);
        toast.success(t("toast.password_reset_success"));

        handleReturnToInitialSignIn();
      } catch {
        toast.error(t("toast.password_reset_failed"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleResendMFACode = async () => {
    try {
      setIsLoadingMfaCodeResend(true);

      await authenticate(data.username, data.password);

      toast.success(t("toast.mfa_code_resent_success"));

      setMfaCodeResendDisabled(true);
      setMfaCodeResendTimer(RESEND_MFA_CODE_TIMEOUT);
    } catch {

      toast.error(t("toast.mfa_code_resent_failed"));
      setMfaCodeResendDisabled(false);
    } finally {
      setIsLoadingMfaCodeResend(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!data.username || !data.password) {
      toast.error(t("toast.fill_all_fields_try_again"));
      return;
    }
  
    try {
      setIsLoading(true);

      await authenticate(data.username, data.password);

      setAuthTemp({ username: data.username , password: data.password });

      setMfaStep(true);
    } catch (error) {
      toast.error(t("toast.auth_failed_try_again")); 
    } finally {
      setIsLoading(false);
    }
  };

  const handleMfaCodeValidation = async (e) => {
    e.preventDefault();
  
    try {
      setIsLoading(true);

      const access_token = await validateMfaCode(authTemp.username, mfaCode, authTemp.password);
  
      saveToken(access_token);
      await setUserInfo(access_token);
  
      const currentUser = JSON.parse(localStorage.getItem("user"));
      if (!currentUser) throw new Error("User not found");
  
      await handleSignInSuccess(currentUser, access_token);

      let appToken = pushToken;
      if (!appToken) {
        appToken = getPushTokenFromQueryStringURL();
      }

      if (!currentUser.is_company_owner_user && appToken) {
        await editMyUserSettings({
          app_token: appToken,
        });
      }

      if (!currentUser.is_company_owner_user && !window.OneSignal.pendingInit && !appToken) {
        const webPushActive = await window.OneSignal.isPushNotificationsEnabled();
        const playerId = await window.OneSignal.getUserId();

        if (!webPushActive) {
          window.OneSignal.showSlidedownPrompt({ force: true });
        }

        await editMyUserSettings({
          web_token: webPushActive ? playerId : "",
        });
      }

      const previousPath = getPreviousPath();

      if (previousPath) {
        removePreviousPath();
        navigate(previousPath);
      } else {
        navigate(routes.goalsOverview);
      }  
    } catch (error) {
      toast.error(t("toast.invalid_mfa_otp_code"));
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (mfaCodeResendTimer > 0 && mfaStep) {
      const interval = setInterval(() => {
        setMfaCodeResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (mfaCodeResendTimer === 0) {
      setMfaCodeResendDisabled(false);
    }
  }, [mfaCodeResendTimer, mfaStep]);

  useEffect(() => {
    if (authenticated) {
      navigate(routes.goalsOverview);
    }
    setPushToken(getPushTokenFromQueryStringURL());
    getAndSetLanguageFromQueryStringURL();

  }, 
  [
    authenticated, 
    navigate, 
    getPushTokenFromQueryStringURL, 
    getAndSetLanguageFromQueryStringURL
  ]);

  return (
    <Container>
      <Login>
        <img src={logo} alt="Biso" />
        <form onSubmit={mfaStep ? handleMfaCodeValidation : forgetPassword ? handleForgetPassword : handleLogin}>
          {!mfaStep && (
            <>
              <div className="containerTitle">
                <p className="welcomeTitle">{t("signIn.welcome")}</p>
                <p className="title">
                  {forgetPassword ? t("signIn.password_recovery") : t("signIn.access_dashboard")}
                </p>
                <p className="descriptionTitle">
                  {forgetPassword
                    ? t("signIn.enter_login_recover_password")
                    : t("signIn.enter_login_password")}
                </p>
              </div>

              {forgetPassword ? (
                <>
                  <p className="descriptionForgetPassword">{t("signIn.password_reset_instructions")}</p>
                  <div className="input">
                    <input
                      type="text"
                      placeholder={t("users.user")}
                      value={forgetPasswordUsername}
                      onChange={(e) => setForgetPasswordUsername(e.target.value)}
                    />
                  </div>
                  <button type="submit" disabled={isLoading}>
                    {t("signIn.send")}
                    {isLoading && (
                      <Loader
                        size={20}
                        color="#fff"
                        containerStyles={{ width: "auto", height: "auto", marginLeft: 5 }}
                      />
                    )}
                  </button>
                  <div className="signin">
                    <span onClick={handleReturnToInitialSignIn}>{t("signIn.log_in")}</span>
                  </div>
                </>
              ) : (
                <>
                  <div className="input">
                    <input
                      type="text"
                      placeholder={t("users.user")}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input">
                    <input
                      type="password"
                      placeholder={t("users.password")}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <button type="submit" disabled={isLoading}>
                    {t("signIn.access_now")}
                    {isLoading && (
                      <Loader
                        size={20}
                        color="#fff"
                        containerStyles={{ width: "auto", height: "auto", marginLeft: 5 }}
                      />
                    )}
                  </button>
                  <div className="forgotPassword">
                    <span onClick={() => setForgetPassword(true)}>
                      {t("signIn.forgot_password")}
                    </span>
                  </div>
                </>
              )}
            </>
          )}

          {mfaStep && (
            <>
            <div className="containerTitle">
              <p className="welcomeTitle">{t("signIn.welcome")}</p>
              <p className="title">{t("signIn.keeping_you_safe")}</p>
              <p className="descriptionTitle">{t("signIn.mfa_instruction_1")}</p>
              <p className="descriptionTitle">{t("signIn.mfa_instruction_2")}</p>
            </div>
            <div className="input">
              <input
                type="text"
                placeholder={t("signIn.mfa_placeholder")}
                value={mfaCode}
                onChange={(e) => handleMfaInputChange(e.target.value)}
                disabled={isLoading}
              />
            </div>
            <button
              type="submit"
              disabled={!mfaCode || isLoading}
            >
              {t("signIn.access_now")}
              {isLoading && (
                  <Loader
                    size={20}
                    color="#fff"
                    containerStyles={{ width: "auto", height: "auto", marginLeft: 5 }}
                  />
                )}
            </button>
            <button
              type="button"
              onClick={handleResendMFACode}
              disabled={mfaCodeResendDisabled || isLoading || isLoadingMfaCodeResend}
            >
              {t("signIn.resend_code")} 
              {(!isLoadingMfaCodeResend && mfaCodeResendDisabled && mfaCodeResendTimer > 0) ? ` (${mfaCodeResendTimer})` : ""}
              {isLoadingMfaCodeResend && (
                <Loader
                  size={20}
                  color="#fff"
                  containerStyles={{ width: "auto", height: "auto", marginLeft: 5 }}
                />
              )}
            </button>
            <div className="signin">
              <span onClick={handleReturnToInitialSignIn}>{t("signIn.log_in")}</span>
            </div>
          </>
          )}
        </form>
        <SelectLanguage onChange={e => handleChangeSignInLanguage(e.target.value)} value={lang}>
          <option value={LANGUAGE.pt_BR}>{t('preferences.portuguese_brazil')}</option>
          <option value={LANGUAGE.en_US}>{t('preferences.english')}</option>
          <option value={LANGUAGE.es}>{t('preferences.spanish')}</option>
        </SelectLanguage>
      </Login>
    </Container>
  );
}

export default SignIn;
