import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  .main {
    background-color: #f4f4f4;
    position: absolute;
    min-height: 100vh;
    left: 59px;
    right: 0;
    padding: 0 20px;
    padding-bottom: 120px;
    padding-top: 94px;

    @media (max-width: 1200px) {
      left: 0;
    }

    .subTitle {
      color: #2e3039;
      font-size: 17px;
      font-weight: 300;

      @media (max-width: 480px) {
        font-size: 15px;
      }
    }

    .containerTitlePage {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 32px;

      .titlePage {
        color: #242e42;
        font-size: 36px;
        font-weight: 300;

        display: flex;
        align-items: center;

        @media (max-width: 480px) {
          font-size: 26px;
        }

        .information-icon {
          margin-left: 10px;
          display: flex;
          align-items: center;

          svg {
            cursor: pointer;
            transition: all 0.1s ease-in-out;
          }

          svg:hover {
            color: ${darken(0.05, "#ff0068")} !important;
          }
        }
      }
    }

    .content {
      border-radius: 5px;
      width: 100%;
      color: #fff;
      background-color: transparent;
      margin-top: 30px;
    }

    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #f4f4f4;
      padding: 10px 20px;
      color: #242e42;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: #ff0068;
        text-decoration: none;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
          color: ${darken(0.05, "#ff0068")};
          text-decoration: underline;
        }
      }

      @media (max-width: 480px) {
        font-size: 11px;
      }
    }
  }
`;

export const HeaderButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderButtonAction = styled.button`
  display: flex;
  align-items: center;
  color: #fff;
  transition: background-color 0.2s;
  border: 0;
  box-shadow: 0 2px 20px rgba(255, 0, 104, 0.3);
  background-color: #ff0068;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.21px;
  border-radius: 20px;
  height: 35px;
  padding: 0 15px;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: ${darken(0.05, "#ff0068")};
    border-color: ${darken(0.05, "#ff0068")};
    color: #fff;
  }
`;
