import React, { useState } from 'react';
import * as S from './styled';

function InfoBar({ children, showCloseButton = true, ...props }) {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) {
    return null;
  }

  return (
    <S.InfoBar {...props}>
      <span>{children}</span>
      {showCloseButton && (
        <button className="close-btn" onClick={handleClose}>
        &times;
      </button>
      )}
    </S.InfoBar>
  );
}

export default InfoBar;