import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import "../../../i18n";

import { InfoBar } from "./../../InfoBar";
import { CustomModalAppToken } from "../../CustomModal";
import { getCurrentUserInfo } from "./../../../utils/loginUtils";

function InfoBarInstallApp(props) {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const [hasAppToken, setHasAppToken] = useState(true);

  const handleClick = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const { is_company_owner_user, app_token } = getCurrentUserInfo();

    const validateAppToken = async () => {
      if (!is_company_owner_user) {
        setHasAppToken(!!app_token);
      }
    };
    validateAppToken();
  }, []);

  return (
    <>
      {!hasAppToken && (
        <>
          <InfoBar {...props}>
            <span>
              {t("common.setup_device")} <strong style={{cursor: "pointer", textDecoration: "underline"}} onClick={handleClick}>{t("common.click_here")}</strong> {t("common.learn_more")}
            </span>
          </InfoBar>
          <CustomModalAppToken open={openModal} handleClose={closeModal} />
        </>
      )}
    </>
  );
}

export default InfoBarInstallApp;
