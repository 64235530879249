import styled from 'styled-components';
import { darken } from "polished";

export const CookieBannerContainerMessage = styled.div`
    a {
        color: #ff0068;
        text-decoration: none;

        &:hover {
          color: ${darken(0.05, "#ff0068")};
          text-decoration: underline;
        }
    }
`;

export const CookieBannerContainerButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
`;