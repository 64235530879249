import { saveToken, setUserInfo, getCurrentUserInfo } from "../../../utils/loginUtils";
import { changeCompany, getCompanies } from "../../../services/odinService";
import { useEffect, useState, useCallback, useContext } from "react";
import BasicSelect from "../../BasicSelect";
import { AuthContext } from "../../../contexts";
import { useTranslation } from "react-i18next";

const SelectCompany = ({ isMobile = false }) => {
  const { user, setUser, configureCompanyPreferences } = useContext(AuthContext);
  const { t } = useTranslation();

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isBisoUser, setIsBisoUser] = useState(false);

  const getCompaniesData = useCallback(async () => {
    if (isBisoUser) {
      const companies = await getCompanies();
      setCompanies(companies ?? []);
      setSelectedCompany(user.company_id);
    }
  }, [user, isBisoUser]);

  useEffect(() => {
    setIsBisoUser(user.user_type === "biso");
  }, [user]);

  useEffect(() => {
    getCompaniesData();
  }, [getCompaniesData]);

  const handleCompanyChange = async (e) => {
    setSelectedCompany(e);
    const response = await changeCompany(e);
    if (response.access_token) {
      saveToken(response.access_token);
      await setUserInfo();
      const user = getCurrentUserInfo();
      setUser(user);
      configureCompanyPreferences(true, user.is_company_owner_user);
      window.location.reload();
    }
  };

  const companyOptions = companies
    .map((company) => ({
      value: company.id,
      label: company.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  if (!selectedCompany) {
    return null;
  }

  if (isBisoUser) {
    return (
      <BasicSelect
        placeholder={t("common.company")}
        options={companyOptions}
        value={selectedCompany}
        setValue={handleCompanyChange}
        width={isMobile ? 100 : 150}
        height={isMobile ? 32 : 48}
        additonalStyles={{ marginRight: 4 }}
      />
    );
  }
  return null;
};

export default SelectCompany;
