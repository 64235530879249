import styled from 'styled-components';
import { darken } from 'polished';

export const SupportButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 48px;
    height: 48px;
    bottom: 20px;
    right: 150px;
    background-color: rgb(0, 204, 174);
    z-index: 1;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
        background: ${darken(0.08, 'rgb(0, 204, 174)')};
    }
`;