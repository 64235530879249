import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../i18n';

import BisoAdvertiseImg from '../../../assets/biso_ads.png';
import CustomModal from '../CustomModal';
import { LANGUAGE } from '../../../options/preference';

import { ModalPageBisoAdvertiseContainer } from './styles';

const ModalPageBisoAdvertise = ({
    lang
}) => {
    const { t } = useTranslation();

    const LAST_UPDATE = '2025_01_24';
    const KEY = `bisoAdvertiseRead_${LAST_UPDATE}`;
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);

        localStorage.setItem(KEY, true);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (lang !== LANGUAGE.pt_BR) {
                return;
            }
    
            const read = localStorage.getItem(KEY);
    
            if (!read) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        }, 3000);
    
        return () => clearTimeout(timer);
    }, [KEY, lang]);

    return (
        <CustomModal
            title={t('common.ad_biso_title')}
            open={open}
            handleClose={handleClose}
            hiddenConfirmButton={true}
            cancelButtonLabel={t('common.close')}
        >
            <ModalPageBisoAdvertiseContainer>
                <div 
                    className="banner" 
                    style={{cursor: 'pointer'}} 
                    onClick={_ => window.open("https://forms.monday.com/forms/5224deb2bc80523a5a7b85159a4de53b?r=use1")}
                >
                    <img src={BisoAdvertiseImg} alt="Ad Biso" width="100%" />
                </div>
                <button className="button" onClick={_ => window.open("https://forms.monday.com/forms/5224deb2bc80523a5a7b85159a4de53b?r=use1")}>{t('common.ad_biso_button')}</button>
            </ModalPageBisoAdvertiseContainer>
        </CustomModal>
    );
}

export default ModalPageBisoAdvertise;