import axios from 'axios';

import { handleSignOut } from '../utils/loginUtils';

const UNAUTHORIZED = 401;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === UNAUTHORIZED) {
      handleSignOut()
    }
    return error
  }
);

export default api;
