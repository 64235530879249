import React, { useEffect } from 'react';

import { BisoTooltip } from '../Tooltip';

import { Container, HeaderButtonAction, HeaderButtonContainer } from './styles';

import { RiInformationLine } from 'react-icons/ri';

import { useTranslation } from "react-i18next";
import "../../i18n";

function Background(props) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div className="main">
        <div className="containerTitlePage">
          <h1 className="titlePage">
            {props.titlePage}
            {props.showInformation && (
              <BisoTooltip 
                title={props.informationTooltipTitle}
                placement={props.informationTooltipPlacement ? props.informationTooltipPlacement : 'right-start'}
              >
                <span className='information-icon' onClick={props.informationOnClick}><RiInformationLine size={35} color="#f90c68"/></span>
              </BisoTooltip>
            )}
          </h1>
          {props.showButtonAction && (<HeaderButtonContainer>{props.showButtonActionComplement ? (props.buttonActionComplement) : null}<HeaderButtonAction onClick={props.buttonActionOnClick}>{props.buttonActionTitle}</HeaderButtonAction></HeaderButtonContainer>)}
        </div>
        {props.subTitlePage && <p className="subTitle">{props.subTitlePage}</p>}
        <div className="content">{props.children}</div>
        <div className='footer'>
          ©2025 Biso | <a href="https://www.biso.digital" target="_blank" rel="noopener noreferrer">{t("common.biso_website")}</a> | <a href="https://www.biso.digital/politica-privacidade" target="_blank" rel="noopener noreferrer">{t("common.privacy_policy")}</a>
        </div>
      </div>
    </Container>
  );
}

export default Background;
