import React, { useState, useEffect, useCallback, useContext } from "react";

import { useTranslation } from "react-i18next";
import "../../../i18n";

import { AuthContext } from "../../../contexts";

import { InfoBar } from "..";

import { CookieBannerContainerMessage, CookieBannerContainerButton } from "./styled";

import Button from "../../Button";

function InfoBarCookieBanner(props) {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);
  
  const [cookieConsent, setCookieConsent] = useState(null); 

  const checkCookieConsentFromLocalStorage = useCallback(() => {
    const cookieConsent = localStorage.getItem("cookieConsent");
    setCookieConsent(cookieConsent);
  }, []);

  const handleSendUserInfoToGoogleAnalytics = useCallback((cookieConsent) => {
    if (cookieConsent === "accepted" && window.gtag && user) {
      window.gtag("set", {
        user_id: user.id,
        user_properties: {
          company_id: user.company_id,
          company_name: user.company_name,
          user_type: user.user_type,
          user_role: user.role
        },
      });
    }
  }, [user]);

  const handleSetUserCookieConsentResponse = (value) => {
    localStorage.setItem("cookieConsent", value);
    setCookieConsent(value);
  }

  useEffect(() => {
    checkCookieConsentFromLocalStorage();
  }, [checkCookieConsentFromLocalStorage]);

  useEffect(() => {
    handleSendUserInfoToGoogleAnalytics(cookieConsent);
  }, [handleSendUserInfoToGoogleAnalytics, cookieConsent]);

  return (
    <>
      {(cookieConsent === null) && (
        <>
          <InfoBar 
            {...props} 
            style={{ zIndex: 4 }}
            showCloseButton={false}
          >
            <CookieBannerContainerMessage>
              <p>{t("common.cookie_consent_message")}</p>
              <br />
              <p>{t("common.cookie_consent_policy_note")} <a href="https://www.biso.digital/politica-privacidade" target="_blank" rel="noopener noreferrer">{t("common.privacy_policy")}</a></p>
            </CookieBannerContainerMessage>
            <CookieBannerContainerButton>
              <Button
                onClick={() => handleSetUserCookieConsentResponse("accepted")}
                text={t("common.cookie_consent_accept_button")}
              />
              <Button
                onClick={() => handleSetUserCookieConsentResponse("declined")}
                text={t("common.cookie_consent_decline_button")}
              />
            </CookieBannerContainerButton>
          </InfoBar>
        </>
      )}
    </>
  );
}

export default InfoBarCookieBanner;
