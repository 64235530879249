/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import '../../i18n';

import { Container} from './styles';

import Loader from '../../components/Loader';
import BackgroundNew from '../../components/BackgroundNew';

import { InsightCard } from './InsightCard';
import { CompletedInsight } from './CompletedInsight';

import { getInsights } from '../../services/insightsService';

import { AuthContext } from '../../contexts';

function BisoInsights() {
  const { t } = useTranslation();
  const { currency } = useContext(AuthContext);

  const [insights, setInsights] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCompletedInsight, setShowCompletedInsight] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState(null);

  const loadInsights = useCallback(async (page = 1, actualInsightsList = []) => {
    try {
      const response = await getInsights(page);

      if (!response) {
        return;
      }

      const result = response["results"];

      const totalPages = response["total_pages"];

      const insightsList = [...actualInsightsList, ...result]

      setInsights(insightsList);
      setTotalPages(totalPages);
      setPage(page);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const setUserFeedbackToInsight = (insightId, feedback) => {
    const updatedInsights = insights.map(insight => {
      if (insight.id === insightId) {
        insight.company_users_feedbacks = [...insight.company_users_feedbacks, feedback];
      }

      return insight;
    });

    setInsights(updatedInsights);
  }

  const handleCloseCompletedInsightModal = () => {
    setShowCompletedInsight(false);
    setSelectedInsight(null);
  }

  const handleOpenCompletedInsightModal = (insight) => {
    setSelectedInsight(insight);
    setShowCompletedInsight(true);
  }

  useEffect(() => {
    loadInsights();
  }, [loadInsights]);

  return (
    <BackgroundNew 
      titlePage={t('menu.biso_insights')}
      subTitlePage={t('bisoInsights.check_insights')}
    >
      {!showCompletedInsight && (
        <Container>
          {(!loading && insights.length > 0) && insights.map((insight, index) => (
            <InsightCard index={index} insight={insight} onClickSeeMore={handleOpenCompletedInsightModal} key={index}/>
          ))}
          {!loading && insights.length === 0 && (
            <div className="noInsights">
              <p>{t('bisoInsights.no_insights_available')}</p>
            </div>
          )}
          {loading && (
            <div className="loading">
              <Loader/>
            </div>
          )}
          {totalPages && page < totalPages && 
            <div className="pagination">
                <button className="loadMoreInsights" onClick={() => loadInsights(page + 1, insights)}>{t('common.load_more')}</button>
            </div>
          }
        </Container>
      )}
      {selectedInsight && showCompletedInsight && (
        <CompletedInsight
            insight={selectedInsight}
            onClose={handleCloseCompletedInsightModal}
            setUserFeedbackToInsight={setUserFeedbackToInsight}
            currency={currency}
        />
      )}
    </BackgroundNew>
  );
}

export default BisoInsights;
